export const States = {
  INITIAL: "Initial",

  VEHICLE: "Vehicle",

  DEALER: "Dealer",

  OPERATIONS: "RepairOperations",

  APPOINTMENT: "Availability",

  CUSTOMER: "Customer",

  REVIEW: "BookingDetails",

  COMPLETE: "Complete",
};

export const Actions = {
  Reset: "Reset",

  Start: "Start",

  SetVehicle: "SetVehicle",

  SetDealer: "SetDealer",

  SetOperations: "SetOperations",

  SetAppointment: "SetAppointment",

  SetCustomer: "SetCustomer",

  Confirm: "BookingCreated",
};

export const initialState = {
  state: States.INITIAL,
};

/*
@startuml

(*) --> [New customer] "Vehicle"
"Vehicle" --> "Dealer"
"Dealer" --> "Operations"
"Operations" --> "Appointment"
"Appointment" --> [New customer] "Customer"
"Customer" --> "Review"
"Review" --> (*)

(*) --> [Existent customer] "Dealer"
"Appointment" --> [Existent customer] "Review"

@enduml
*/

export const reducer = (state, action) => {
  switch (action.type) {
    case Actions.Reset:
      return {
        ...initialState,
      };

    case Actions.Start:
      return {
        ...initialState,
        ...action.payload,
        state: action.payload.existentCustomer ? States.DEALER : States.VEHICLE,
      };

    case Actions.SetVehicle:
      return {
        ...state,
        vehicle: action.payload,
        state: States.DEALER,
      };

    case Actions.SetDealer:
      return {
        ...state,
        dealer: action.payload,
        state: States.OPERATIONS,
      };

    case Actions.SetOperations:
      return {
        ...state,
        repairOperations: action.payload.operations,
        additionalRepairOperations: action.payload.comments,
        state: States.APPOINTMENT,
      };

    case Actions.SetAppointment:
      return {
        ...state,
        availability: action.payload,
        state: state.existentCustomer ? States.REVIEW : States.CUSTOMER,
      };

    case Actions.SetCustomer:
      return {
        ...state,
        customer: action.payload,
        state: States.REVIEW,
      };

    case Actions.Confirm:
      return {
        ...state,
        ...action.payload,
        state: States.COMPLETE,
      };

    default:
      return state;
  }
};
