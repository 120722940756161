import { createContext, useState, useEffect } from "react";
import ContextService from "../services/context";
import { toast } from "react-toastify";

const AppContext = createContext({});

export const AppContextProvider = ({ children }) => {
  const [ctx, setCtx] = useState(null);

  useEffect(() => {
    ContextService.getContext()
      .then((res) => setCtx(res))
      .catch(() => {
        toast.error(
          "Could not start the application. Please refresh the browser's window to try again",
          { onClick: () => window.location.reload() }
        );
      });
  }, []);

  return (
    <AppContext.Provider value={ctx}>
      {ctx == null ? (
        <></>
      ) : (
        <div
          style={{
            fontFamily: (ctx?.brand?.font || "").replaceAll(";", ""),
            backgroundColor: ctx?.brand?.backgroundColor || "",
            color: ctx?.brand?.textColor || "",
          }}
        >
          {children}
        </div>
      )}
    </AppContext.Provider>
  );
};

export default AppContext;
