import { BASE_URL } from "../constants";

const AnalyticsService = {
  createSession: (customer, vehicle) =>
    fetch(`${BASE_URL}/session`, {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ customer, vehicle }),
    }).then((res) =>
      res.ok ? res.json() : res.json().then((r) => Promise.reject(r))
    ),

  updateSession: (sessionId, payload) =>
    fetch(`${BASE_URL}/session/${sessionId}`, {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then((res) =>
      res.ok ? res.json() : res.json().then((r) => Promise.reject(r))
    ),

  closeSession: (sessionId, payload) =>
    fetch(`${BASE_URL}/session/${sessionId}/done`, {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }),

  triggerBeacon: (sessionId, payload) => {
    const blob = new Blob([JSON.stringify(payload)], {
      Accept: "application/json",
      "Content-Type": "application/json",
    });
    navigator.sendBeacon(`${BASE_URL}/session/${sessionId}/done`, blob);
  },
};

export default AnalyticsService;
