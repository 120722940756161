import { useMemo } from "react";
import { Card, Checkbox, Datepicker, Spinner } from "flowbite-react";

const AppointmentOption = ({
  title,
  value,
  selected,
  options,
  price,
  onChange,
}) => {
  const minDate = useMemo(() => {
    if (options && options.length > 0) {
      return new Date(options[0].availableDate);
    }
    return new Date();
  }, [options]);

  const handleSelected = () => {
    if (Array.isArray(options) && options.length > 0) {
      onChange({ value, options, date: minDate });
    }
  };

  return (
    <>
      <Card className="mb-4">
        <div className="flex items-center gap-4">
          <Checkbox
            onClick={handleSelected}
            checked={selected}
            className={`h-6 w-6 ${
              (options || []).length > 0 ? "" : "cursor-not-allowed"
            }`}
          />
          <label className="flex-grow">{title}</label>
          <label className="w-20 text-right">{price}</label>
          <div className="text-center w-48">
            <p>First Availability</p>
            {!Array.isArray(options) ? (
              <Spinner className="h-8 w-8 fill-black" />
            ) : null}
            {Array.isArray(options) && options.length === 0 ? (
              <span>No available options</span>
            ) : null}

            {Array.isArray(options) && options.length > 0 ? (
              <Datepicker
                defaultDate={minDate}
                key={minDate.toString()}
                language="en-GB"
                disabled
              />
            ) : null}
          </div>
        </div>
      </Card>
    </>
  );
};

export default AppointmentOption;
