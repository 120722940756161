import { BASE_URL } from "../constants";

const RTCService = {
  getCustomerDetails: (customerId, vehicleId) =>
    fetch(`${BASE_URL}/details/${customerId}/${vehicleId}`, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
      },
    })
      .then((res) =>
        res.ok
          ? res.json()
          : res.json().then((r) => Promise.reject({ ...r, status: res.status }))
      )
      .then((res) => {
        // console.log("RES:");
        // console.log(res);
        return res.customer && res.vehicle && res.vehicle.registration
          ? Promise.resolve({
              ...res,
              regNum: res.vehicle.registration,
              customer: { ...res.customer, sfCustomerId: customerId },
              vehicle: {
                ...res.vehicle,
                regNo: res.vehicle.registration,
                sfVehicleId: vehicleId,
              },
            })
          : // RTCService.getVehicle(null, res.vehicle.registration)
            //     .then((vehicle) => {
            //       console.log("VEHICLE");
            //       console.log(vehicle);
            //       return {
            //         customer: { ...res.customer, sfCustomerId: customerId },
            //         vehicle: {
            //           id: vehicle.id,
            //           regNo: res.vehicle.registration || vehicle.regNo,
            //           chassis: vehicle.chassis,
            //           engine: vehicle.engine,
            //           "description": vehicle.description,
            //           "colour": vehicle.colour,
            //           "engineCapacity": res.vehicle.engineCapacity || vehicle.engineCapacity,
            //           "regDate": res.vehicle.regDate || vehicle.regDate,
            //           "yearOfManufacture": res.vehicle.yearOfManufacture || vehicle.yearOfManufacture,
            //           "make": res.vehicle.make || vehicle.make,
            //           "model": res.vehicle.model || vehicle.model,
            //           "transmission": res.vehicle.transmission || vehicle.transmission,
            //           "doorPlan": vehicle.doorPlan,
            //           "transmissionCode": vehicle.transmissionCode,
            //           "gears": vehicle.gears,
            //           "fuel": res.vehicle.fuel || vehicle.fuel,
            //           "mileage": vehicle.mileage,
            //           "leaseCompany": vehicle.leaseCompany,
            //           "cO2Emissions": vehicle.cO2Emissions,
            //           ...res.vehicle,
            //           ...vehicle,
            //           sfVehicleId: vehicleId,
            //         },
            //       };
            //     })
            //     .catch(() =>
            //       Promise.resolve({
            //         ...res,
            //         regNum: res.vehicle.registration,
            //         customer: { ...res.customer, sfCustomerId: customerId },
            //         vehicle: {
            //           ...res.vehicle,
            //           regNo: res.vehicle.registration,
            //           sfVehicleId: vehicleId,
            //         },
            //       })
            //     )
            Promise.reject({
              error: "Could not find customer details and/or vehicle",
            });
      }),

  getDealers: () =>
    fetch(`${BASE_URL}`, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
      },
    }).then((res) =>
      res.ok ? res.json() : res.json().then((r) => Promise.reject(r))
    ),

  getVehicle: (dealerId, regNo) =>
    fetch(`${BASE_URL}/vehicles/${regNo}`, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
      },
    }).then((res) =>
      res.ok ? res.json() : res.json().then((r) => Promise.reject(r))
    ),

  getOperations: (dealerId, regNo, model, regDate) =>
    fetch(
      `${BASE_URL}/${dealerId}/vehicles/${
        regNo || ""
      }/operations?model=${model}&reg=${regDate}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
        },
      }
    ).then((res) =>
      res.ok ? res.json() : res.json().then((r) => Promise.reject(r))
    ),

  getAppointments: (dealerId, regNo, model, regDate, from, to, ops) =>
    fetch(
      `${BASE_URL}/${dealerId}/vehicles/${regNo}/availability?model=${model}&reg=${regDate}&from=${from}&to=${to}&${ops
        .map((r) => `code=${r.code}`)
        .join("&")}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
        },
      }
    ).then((res) =>
      res.ok ? res.json() : res.json().then((r) => Promise.reject(r))
    ),

  getPaymentMethods: (dealerId) =>
    fetch(`${BASE_URL}/${dealerId}/payments`, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
      },
    }).then((res) =>
      res.ok ? res.json() : res.json().then((r) => Promise.reject(r))
    ),

  createBooking: (dealerId, booking) =>
    fetch(`${BASE_URL}/${dealerId}/bookings`, {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(booking),
    }).then((res) =>
      res.ok ? res.json() : res.json().then((r) => Promise.reject(r))
    ),
};

export default RTCService;
