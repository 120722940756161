import { useState, useEffect, useContext, useMemo } from "react";

import { Button, Checkbox, Card, Textarea } from "flowbite-react";

import { Actions } from "../reducers/Booking";
import Stepper, { STEPS } from "../components/Stepper";

import rtc from "../services/rtc";
import { FaCar } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import AppContext from "../context/AppContext";

const formatPrice = (price) => {
  return (
    "£" + (price || 0).toLocaleString(undefined, { minimumFractionDigits: 2 })
  );
};

const Page = ({ state, dispatch, onError }) => {
  const [operations, setOperations] = useState(null);
  const [selectedOperations, setSelectedOperations] = useState([]);
  const [comments, setComments] = useState("");

  const { groups } = useContext(AppContext);
  const services = useMemo(
    () => (groups ? groups[state.dealer.dealerId]?.services : {}) || {},
    [state, groups]
  );

  const handleSubmit = () => {
    const selected = operations.filter((op) =>
      selectedOperations.includes(op.code)
    );

    if (selected.length > 0) {
      dispatch({
        type: Actions.SetOperations,
        payload: { operations: selected, comments },
      });
    } else {
      onError({
        error: true,
        field: "operations",
        type: "UI",
        message: "No services selected",
      });
    }
  };

  const handleToggleOperation = (code) => {
    setSelectedOperations((prev) => {
      if (prev.includes(code)) {
        return prev.filter((p) => p !== code);
      }

      return [...prev, code];
    });
  };

  useEffect(() => {
    rtc
      .getOperations(
        state.dealer.dealerId,
        state.vehicle.regnum,
        state.vehicle.model || "",
        state.vehicle.regDate || ""
      )
      .then((json) => setOperations(json))
      .catch((err) => console.error(err));
  }, [state]);

  return (
    <>
      <Stepper step={STEPS.Appointment} />

      <h2 className="text-xl block mb-8">
        Please select the service you require
      </h2>

      <div className="flex md:flex-row gap-8">
        {Array.isArray(operations) && (
          <>
            {operations
              .filter(
                (op) =>
                  !(services[op.code] && services[op.code].visible === false)
              )
              .filter((op) => services[op.code]?.primary === true)
              .map((op) => (
                <div className="md:flex-grow" key={op.code}>
                  <Card>
                    <div className="flex items-center gap-2">
                      <Checkbox
                        onClick={() => handleToggleOperation(op.code)}
                        value={selectedOperations.includes(op.code)}
                        className="h-6 w-6"
                      />
                      <label
                        className="flex-grow"
                        title={`${services[op.code]?.name || op.description} (${
                          op.code
                        })`}
                      >
                        {services[op.code]?.name || op.description}
                      </label>
                    </div>
                  </Card>
                </div>
              ))}
          </>
        )}
      </div>

      <h2 className="text-xl block my-8">
        Please select the additional service you require
      </h2>

      {Array.isArray(operations) && (
        <>
          {operations
            .filter(
              (op) =>
                !(services[op.code] && services[op.code].visible === false)
            )
            .filter((op) => !(services[op.code]?.primary === true))
            .map((op) => (
              <Card className="mb-4" key={op.code}>
                <div className="flex items-center gap-4">
                  <Checkbox
                    onClick={() => handleToggleOperation(op.code)}
                    value={selectedOperations.includes(op.code)}
                    className="h-6 w-6"
                  />
                  <label
                    className="flex-grow"
                    title={`${services[op.code]?.name || op.description} (${
                      op.code
                    })`}
                  >
                    {services[op.code]?.name || op.description}
                  </label>
                  <span>{formatPrice(op.price)}</span>
                </div>
              </Card>
            ))}
        </>
      )}

      <h2 className="text-xl block my-8">
        If your vehicle requires any other work please let us know here
      </h2>

      <Textarea
        rows={4}
        value={comments}
        onChange={(evt) => setComments(evt.target.value)}
      />

      <p className="text-sm block mt-2 mb-4">
        If your vehicle requires diagnostic work we may need to reschedule your
        appointment. If this is the case, a member of our team will be in touch
        shortly
      </p>

      <Button
        type="button"
        color="dark"
        className="w-full md:w-3/4 md:m-auto"
        onClick={handleSubmit}
      >
        Continue
      </Button>
    </>
  );
};

const Description = ({ state }) => {
  const {
    brand: { textColor, textShadeColor },
  } = useContext(AppContext);

  return (
    <>
      <h2 className="text-4xl font-bold" style={{ color: textColor }}>
        Your booking
      </h2>
      <div className="pb-4 pt-8" style={{ color: textColor }}>
        <FaCar size="2em" />
      </div>
      <h3 className="text-2xl font-bold pb-4" style={{ color: textColor }}>
        Your Vehicle
      </h3>
      <p className="text-xl text-slate-500" style={{ color: textShadeColor }}>
        {state.vehicle.regnum}
      </p>
      <p className="text-xl text-slate-500" style={{ color: textShadeColor }}>
        {state.vehicle.description}
      </p>

      <div className="pb-4 pt-8" style={{ color: textColor }}>
        <FaMapMarkerAlt size="2em" />
      </div>
      <h3 className="text-2xl font-bold pb-4" style={{ color: textColor }}>
        Selected Location
      </h3>
      <p className="text-xl text-slate-500" style={{ color: textShadeColor }}>
        {state.dealer.description}
      </p>
    </>
  );
};

const tuple = { Page, Description };

export default tuple;
